import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Upload,
  Layout,
  Select,
  Typography,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigateObject } from '../layout/redux/modules/layout';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { getUUID, isLogged } from 'helpers/utils';
import RightPanel from './RightPanel';
import { getHelpDeskLov, submitHelpDeskForm } from './redux/helpdesk';

const { TextArea } = Input;
const { Option } = Select;
const { Header, Footer, Content } = Layout;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const HelpDesk = (_props) => {
  const dispatch = useDispatch();

  const { defaultAccess, userEmail, userFirstName } = useSelector(
    ({ authorization }) => ({
      defaultAccess: authorization.initAuthorization.defaultAccess,
      userEmail: authorization.initAuthorization.email,
      userFirstName: authorization.initAuthorization.userName,
    })
  );

  const dropDownData = useSelector(({ helpdesk }) => helpdesk.lovData);
  const submittingForm = useSelector(({ helpdesk }) => helpdesk.submitting);

  const [form] = Form.useForm();
  const [moduleSelected, setModuleSelected] = useState('');
  const [roleSelected, setRoleSelected] = useState('');
  let navigate = useNavigate();

  const {
    submitterRoleList = [],
    moduleList = [],
    problemTypeList = [],
    contactList = {},
  } = dropDownData || {};

  useEffect(() => {
    dispatch(setNavigateObject(navigate));
    dispatch(getHelpDeskLov());
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      email: userEmail,
      name: userFirstName,
    });
  }, [defaultAccess]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getLogo = () => {
    return (
      <img
        src={`${process.env.PUBLIC_URL}/images/logo/logo.svg`}
        alt='Sanmina Corporation'
        className='logo-avator'
      />
    );
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = () => {
    form.validateFields().then((values) => {
      dispatch(submitHelpDeskForm(values, form));
    });
  };

  const displayUserDetailsInput = () => {
    if (roleSelected) {
      if (roleSelected == 'Plant') {
        return (
          <Form.Item
            name='username'
            label='Universal Login'
            rules={[
              {
                required: true,
                message: 'Universal login is required',
              },
            ]}>
            <Input size='small' />
          </Form.Item>
        );
      } else if (roleSelected == 'Supplier') {
        return (
          <>
            <Form.Item
              initialvalue={userEmail}
              name='name'
              label='Name'
              rules={[{ required: true }]}>
              <Input size='small' />
            </Form.Item>
            <Form.Item
              initialvalue={userEmail}
              name='email'
              label='Email'
              rules={[{ required: true, type: 'email' }]}>
              <Input size='small' />
            </Form.Item>
            <Form.Item
              initialvalue={userEmail}
              name='phoneNumber'
              label='Phone Number'
              rules={[
                {
                  required: true,
                },
              ]}>
              <Input size='small' />
            </Form.Item>
          </>
        );
      }
    }
  };

  const showPlantUserName = () => {
    if ((roleSelected && roleSelected === 'Plant') || !roleSelected) {
      return (
        <Form.Item name='plantUserName' label='Plant User Name'>
          <Input size='small' />
        </Form.Item>
      );
    }
  };

  return (
    <Layout>
      <Header
        style={{
          height: '46px',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
        }}>
        <Row>
          <Col span={4} flex='auto'>
            <div className='helpdesk-logo'>{getLogo()}</div>
          </Col>
          <Col span={16}>
            <div className='helpdesk-header'>
              {isLogged()
                ? `Sanmina Supplier Portal Help Desk Service Request entry`
                : `Partner Portal Training Issues Only`}
            </div>
          </Col>
          <Col span={4}></Col>
        </Row>
      </Header>
      <Content
        style={{
          marginTop: '46px',
          overflow: 'auto',
          height: 'calc(100vh - 70px)',
          background: '#fff',
        }}>
        <Row>
          <Col xs={14} sm={14} md={14} style={{ padding: '0 20px' }}>
            <Form
              id='form-file-upload'
              onFinish={onFinish}
              form={form}
              {...layout}
              name='nest-messages'
              validateMessages={validateMessages}>
              <Form.Item
                label='Submitter Role'
                name='submitterRole'
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Submitter Role!',
                  },
                  () => ({
                    validator(_rule, value) {
                      if (value != '') {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  }),
                ]}>
                <Select
                  showSearch
                  mode='single'
                  size='small'
                  allowClear
                  onChange={(value) => setRoleSelected(value)}
                  placeholder='Select a Submitter Role'>
                  {submitterRoleList?.map((roles) => (
                    <Option key={getUUID()} value={roles}>
                      {roles}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {displayUserDetailsInput()}
              <Form.Item
                label='Module'
                name='module'
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Module!',
                  },
                  () => ({
                    validator(_rule, value) {
                      if (value != '') {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                  }),
                ]}>
                <Select
                  showSearch
                  mode='single'
                  size='small'
                  allowClear
                  onChange={(value) => setModuleSelected(value)}
                  placeholder='Select a Module'>
                  {moduleList?.map(({ label, value }) => (
                    <Option key={getUUID()} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Typography.Text style={{ color: '#1890ff' }}>
                (If you are submitting this Service Request for another user,
                please specify that person's username here)
              </Typography.Text>
              {showPlantUserName()}
              <Form.Item name='supplierUserName' label='Supplier User Name'>
                <Input size='small' />
              </Form.Item>
              <Form.Item
                label='Problem Type'
                name='problemType'
                rules={[
                  {
                    required: true,
                    message: 'Please Select a Problem Type!',
                  },
                ]}>
                <Select
                  showSearch
                  mode='single'
                  size='small'
                  allowClear
                  placeholder='Select a Problem Type'>
                  {problemTypeList?.[moduleSelected]?.map((types) => (
                    <Option key={getUUID()} value={types}>
                      {types}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Typography.Text style={{ color: '#1890ff' }}>
                (To attach multiple documents, please zip them into a single
                file)
              </Typography.Text>
              <Form.Item
                name='fileAttachment'
                label='File Attachment'
                valuePropName='fileList'
                getValueFromEvent={normFile}>
                <Upload
                  listType='text'
                  multiple={false}
                  maxCount={1}
                  customRequest={({ onSuccess }) => onSuccess('ok')}>
                  <Button size='small' icon={<UploadOutlined />}>
                    Click to upload
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                name='description'
                label='Description'
                rules={[
                  {
                    required: true,
                    message: 'Please enter the Description!',
                  },
                ]}>
                <TextArea
                  size='small'
                  rows={3}
                  placeholder='Please enter the Description'
                />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 6 }}>
                <Button
                  style={{ marginTop: '1px' }}
                  type='primary'
                  loading={submittingForm}
                  size='small'
                  htmlType='submit'>
                  {submittingForm ? `Submitting` : `Submit`}
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={10} sm={10} md={10} style={{ padding: '0 20px' }}>
            {contactList[moduleSelected] && (
              <RightPanel content={contactList[moduleSelected]} />
            )}
          </Col>
        </Row>
      </Content>
      <Footer>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <div
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: 12,
              }}>
              For the status of an open Service Request, please contact the
              Sanmina Help Desk at +1 256 880 4883
            </div>
          </Col>
        </Row>
      </Footer>
    </Layout>
  );
};

export default HelpDesk;
