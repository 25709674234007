import { message, Modal } from 'antd';
import { isObject } from 'helpers/utils';
import { Link } from 'react-router-dom';

const HELPDESK_DROPDOWN_DATA = 'helpdesk/HELPDESK_DROPDOWN_DATA';
const HELPDESK_DROPDOWN_DATA_SUCCESS =
  'helpdesk/HELPDESK_DROPDOWN_DATA_SUCCESS';
const HELPDESK_DROPDOWN_DATA_FAIL = 'helpdesk/HELPDESK_DROPDOWN_DATA_FAIL';

const HELPDESK_COMMIT = 'helpdesk/HELPDESK_COMMIT';
const HELPDESK_COMMIT_SUCCESS = 'helpdesk/HELPDESK_COMMIT_SUCCESS';
const HELPDESK_COMMIT_FAIL = 'helpdesk/HELPDESK_COMMIT_FAIL';

const helpdesk = (state = {}, action) => {
  switch (action.type) {
    case HELPDESK_DROPDOWN_DATA:
      return {
        ...state,
        loadingLov: true,
        lovData: {},
      };
    case HELPDESK_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loadingLov: false,
        lovData: action.payload,
      };
    case HELPDESK_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        loadingLov: false,
        error: action.payload,
      };
    case HELPDESK_COMMIT:
      return {
        ...state,
        submitting: true,
      };
    case HELPDESK_COMMIT_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case HELPDESK_COMMIT_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getHelpDeskLov = () => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: HELPDESK_DROPDOWN_DATA });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'HELPDESK_DROPDOWN_DATA',
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200') {
          if (
            isObject(data.result) &&
            Object.getOwnPropertyNames(data.result).length
          ) {
            dispatch({
              type: HELPDESK_DROPDOWN_DATA_SUCCESS,
              payload: data.result,
            });
          }
        } else {
          dispatch({
            type: HELPDESK_DROPDOWN_DATA_FAIL,
            payload: 'Something went wrong!',
          });
        }
      })
      .catch((err) => {
        dispatch({ type: HELPDESK_DROPDOWN_DATA_FAIL, payload: err });
      });
  };
};

const getSrLink = (data) => {
  return (
    <Link to={data.result.SrLink} target='_blank'>
      clicking here
    </Link>
  );
};

const submitHelpDeskForm = (formDetails, formInstance) => {
  return (dispatch, _getState, { api, formatParams }) => {
    dispatch({ type: HELPDESK_COMMIT });
    // Helpdesk form submission
    const { fileAttachment, ...rest } = formDetails;

    const formatData = (label, key) => {
      if (rest[key] && rest[key] !== '') {
        return `${label}: ${rest[key]}\n`;
      }
      return '';
    };

    let formDet = rest;
    let description = '';
    if (formDet.submitterRole === 'Supplier') {
      description = `*** Created by SR Template -  Supplier Portal  ***\n${
        formDet.description
      }\n\nUser Info:-\nRole: ${formDet.submitterRole}\nName: ${
        formDet.name
      }\nEmail: ${formDet.email}\nTelephone: ${
        formDet.phoneNumber
      }\n${formatData('Supplier User Name', 'supplierUserName')}ProblemType: ${
        formDet.problemType
      }`;
    } else if (formDet.submitterRole === 'Plant') {
      description = `*** Created by SR Template -  Supplier Portal  ***\n${
        formDet.description
      }\n\nUser Info:-\nRole: ${formDet.submitterRole}\nUniversal Login: ${
        formDet.username
      }\n${formatData('Plant User Name', 'plantUserName')}${formatData(
        'Supplier User Name',
        'supplierUserName'
      )}ProblemType: ${formDet.problemType}`;
    }

    formDet = { ...formDet, description };

    const details = formatParams({
      type: 'SUBMIT_HELPDESK_DATA',
      details: {
        ...formDet,
      },
    });

    let formData = new FormData();
    formData.append('payload', JSON.stringify(details));

    if (fileAttachment?.length) {
      fileAttachment.forEach((d) => {
        formData.append('attachment', d.originFileObj);
      });
    } else {
      formData.append('attachment', new File([''], 'empty'));
    }

    return api
      .post('pc', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.statusCode == '200') {
          dispatch({ type: HELPDESK_COMMIT_SUCCESS });
          /* prettier-ignore */
          Modal.success({
            title: data.result.returnMsg,
            content: `Your new service request number is ${
              data.result.SrID
            }. To contact the Sanmina Help Desk, please phone +1 256 880 4883. You may visit it by ${getSrLink(data)}` /* NOSONAR */,
          });
          formInstance.resetFields();
        } else {
          dispatch({ type: HELPDESK_COMMIT_FAIL });
          message.error('Something went wrong. Please try again!');
        }
      });
  };
};

export default helpdesk;

export { getHelpDeskLov, submitHelpDeskForm };
