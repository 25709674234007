import store from '../../../../redux/store';
import service from '../../../../service/Service';
import { getLastRowIndex } from '../../../../helpers/utils';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import Cookies from 'js-cookie';

export const INVENTORY_HISTORY_COLUMNS =
  'pc/inv/supplier-inventory-history/INVENTORY_HISTORY_COLUMNS';
export const INVENTORY_HISTORY_COLUMNS_SUCCESS =
  'pc/inv/supplier-inventory-history/INVENTORY_HISTORY_COLUMNS_SUCCESS';
export const INVENTORY_HISTORY_COLUMNS_FAIL =
  'pc/inv/supplier-inventory-history/INVENTORY_HISTORY_COLUMNS_FAIL';

export const INVENTORY_HISTORY_ROWS_INFO =
  'pc/inv/supplier-inventory-history/INVENTORY_HISTORY_ROWS_INFO';

export const DESTROY_INVENTORY_HISTORY_BY_KEY =
  'pc/inv/supplier-inventory-history/DESTROY_INVENTORY_HISTORY_BY_KEY';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    inventoryHistoryColumns: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INVENTORY_HISTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case INVENTORY_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          inventoryHistoryColumns: action.payload,
          loaded: true,
        },
      };
    case INVENTORY_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case INVENTORY_HISTORY_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          rowsInfo: action.payload,
        },
      };
    case DESTROY_INVENTORY_HISTORY_BY_KEY:
      delete state[action.tabKey];
      return state;
    default:
      return state;
  }
}

const stringify = (val = '') => {
  if (typeof val !== 'string') {
    const filteredVal = val.filter((data) => data !== 'all');
    return filteredVal.join();
  } else {
    return val;
  }
};

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};
  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

function getInventoryHistoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: INVENTORY_HISTORY_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'INVENTORY_HISTORY',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let configs = data.result;
          dispatch({
            type: INVENTORY_HISTORY_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: INVENTORY_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

function getInventoryHistoryRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const qsState = store.getState().pc.search.quickSearch;
      const tabData = qsState[tabKey] || {};
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'INVENTORY_HISTORY',
              details: {
                email: Cookies.get('email'),
                customer_plant: stringify(tabData.customerPlant),
                supplier_name: stringify(tabData.supplierName),
                supplier_code: stringify(tabData.supplierCode),
                supplier_key: stringify(tabData.supplierDepot),
                agility_type: stringify(tabData.agilityType),
                search_term: tabData.searchTerm,
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row, index) => {
              let invoiceCalcs = {};
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
                ...invoiceCalcs,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: INVENTORY_HISTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
              details: {
                email: Cookies.get('email'),
                ...getQsProps(tabKey),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookies.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroyInventoryHistoryByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_INVENTORY_HISTORY_BY_KEY, tabKey });
  };
};

export {
  getInventoryHistoryColumns,
  getInventoryHistoryRows,
  destroyInventoryHistoryByKey,
};
