import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { saveTourDetails, getwalkthroughData } from './redux/tour';

const Handletour = (props) => {
  //Dashboard
  let adminCdsDashboard = useRef(null);
  let customerNameCdsTour = useRef(null);
  let customerRowsCdsTour = useRef(null);
  let customerCodeCdsTour = useRef(null);
  let newReportCdsTour = useRef(null);
  let reportHistoryCdsTour = useRef(null);
  let exportHistoryCdsTour = useRef(null);
  let reportSettingsCdsTour = useRef(null);
  let orderStatusCdsTour = useRef(null);
  let orderHistoryCdsTour = useRef(null);
  //Dashboard screen
  let itAdminScreen = useRef(null);
  let ordersScreen = useRef(null);
  let reportsScreen = useRef(null);
  let refreshScreen = useRef(null);
  let customerReportDetails = useRef(null);
  let ordersScreenPeriod = useRef(null);
  let customerCode = useRef(null);
  let customerName = useRef(null);
  let customerReportCount = useRef(null);
  let ordersGraph = useRef(null);
  let cdsReportsList = useRef(null);

  // Report Settings
  let reportSettingsUpdateLinkCds = useRef(null);
  let reportSettingsFilterCds = useRef(null);
  let reportSettingsDeleteCds = useRef(null);
  let reportSettingsDownloadCds = useRef(null);
  let tabCloseTour = useRef(null);
  let profileSpaceTour = useRef(null);
  let profileCdsTour = useRef(null);
  // New Report Setup
  let newReportSetup = useRef(null);
  let newReportColumn = useRef(null);
  let newReportRefresh = useRef(null);
  let newReportCustomer = useRef(null);
  let newReportComm = useRef(null);
  // New Report Column
  let newReportReportName = useRef(null);
  let newReportColumnTrack = useRef(null);
  let newReportColumnLabel = useRef(null);
  // New Report Refresh
  let newReportRefreshTime = useRef(null);
  let newReportRefreshValidate = useRef(null);
  let newReportCustomerValidate = useRef(null);
  let newReportSubmit = useRef(null);
  // Report History
  let reportHistorySelect = useRef(null);
  let reportHistoryCreated = useRef(null);
  let reportHistoryCustomerCode = useRef(null);
  let reportHistoryDownload = useRef(null);
  // Dashboard Inner
  let reportTitle = useRef();
  let reportRowCount = useRef();
  let reportRefreshDate = useRef();
  let reportHistory = useRef();
  // Update report
  let reportSwitch = useRef();
  // Profile
  let profileEnable = useRef();
  let profilePassword = useRef();
  let profileDelimiter = useRef();
  let profileSupportDoc = useRef();
  // Acp Open
  let openTabAdmin = useRef(null);
  let closedTabAdmin = useRef(null);
  let historyTabAdmin = useRef(null);
  let acpOrgCode = useRef(null);
  let acpClearFilter = useRef(null);
  let acpColumnConfig = useRef(null);
  let acpItemStatus = useRef(null);
  let excelDownload = useRef(null);
  let acpOrgCodeRsm = useRef(null);
  let acpItemStatusRsm = useRef(null);
  let acpOrgCodeSupplier = useRef(null);
  let acpItemStatusSupplier = useRef(null);
  // Order Report
  let orderReportsParentScreen = useRef(null);
  let orderReportsGraph = useRef(null);
  let orderReportFilter = useRef(null);
  let orderReportName = useRef(null);
  let orderReportCustomerPlant = useRef(null);
  let orderReportDuration = useRef(null);
  let orderReportChartType = useRef(null);

  // Order Status
  let orderStatusLinkCds = useRef(null);
  let orderStatusFilterCds = useRef(null);
  let orderStatusDownloadCds = useRef(null);

  // Order History
  let orderHistoryLinkCds = useRef(null);
  let orderHistoryFilterCds = useRef(null);
  let orderHistoryDownloadCds = useRef(null);

  // ACP Support Doc
  let candidateItem = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    let getDefaultAccess = props.defaultAccess;
    if (
      getDefaultAccess === 'CDS' ||
      getDefaultAccess === 'ACP' ||
      getDefaultAccess === 'PC'
    ) {
      dispatch(getwalkthroughData());
      handleTourDetails(props.defaultAccess);
    }
  }, [props.defaultAccess]);
  const handleTourDetails = (module) => {
    let tourDetails = {};
    tourDetails = {
      adminCdsDashboard: adminCdsDashboard,
      customerNameCdsTour: customerNameCdsTour,
      customerRowsCdsTour: customerRowsCdsTour,
      customerCodeCdsTour: customerCodeCdsTour,
      newReportCdsTour: newReportCdsTour,
      reportHistoryCdsTour: reportHistoryCdsTour,
      exportHistoryCdsTour: exportHistoryCdsTour,
      reportSettingsCdsTour: reportSettingsCdsTour,
      reportSettingsUpdateLinkCds: reportSettingsUpdateLinkCds,
      reportSettingsFilterCds: reportSettingsFilterCds,
      reportSettingsDeleteCds: reportSettingsDeleteCds,
      reportSettingsDownloadCds: reportSettingsDownloadCds,
      tabCloseTour: tabCloseTour,
      profileCdsTour: profileCdsTour,
      profileSpaceTour: profileSpaceTour,
      newReportSetup: newReportSetup,
      newReportColumn: newReportColumn,
      newReportRefresh: newReportRefresh,
      newReportCustomer: newReportCustomer,
      newReportComm: newReportComm,
      newReportReportName: newReportReportName,
      newReportColumnTrack: newReportColumnTrack,
      newReportColumnLabel: newReportColumnLabel,
      newReportRefreshTime: newReportRefreshTime,
      newReportRefreshValidate: newReportRefreshValidate,
      newReportCustomerValidate: newReportCustomerValidate,
      newReportSubmit: newReportSubmit,
      reportHistorySelect: reportHistorySelect,
      reportHistoryCreated: reportHistoryCreated,
      reportHistoryCustomerCode: reportHistoryCustomerCode,
      reportHistoryDownload: reportHistoryDownload,
      reportTitle: reportTitle,
      reportRowCount: reportRowCount,
      reportRefreshDate: reportRefreshDate,
      reportHistory: reportHistory,
      reportSwitch: reportSwitch,
      orderStatusCdsTour: orderStatusCdsTour,
      orderHistoryCdsTour: orderHistoryCdsTour,
      profileEnable: profileEnable,
      profilePassword: profilePassword,
      profileDelimiter: profileDelimiter,
      profileSupportDoc: profileSupportDoc,
      openTabAdmin: openTabAdmin,
      closedTabAdmin: closedTabAdmin,
      historyTabAdmin: historyTabAdmin,
      acpOrgCode: acpOrgCode,
      acpClearFilter: acpClearFilter,
      acpColumnConfig: acpColumnConfig,
      acpItemStatus: acpItemStatus,
      excelDownload: excelDownload,
      candidateItem: candidateItem,
      acpOrgCodeRsm: acpOrgCodeRsm,
      acpItemStatusRsm: acpItemStatusRsm,
      acpOrgCodeSupplier: acpOrgCodeSupplier,
      acpItemStatusSupplier: acpItemStatusSupplier,
      itAdminScreen: itAdminScreen,
      ordersScreen: ordersScreen,
      reportsScreen: reportsScreen,
      refreshScreen: refreshScreen,
      customerReportDetails: customerReportDetails,
      ordersScreenPeriod: ordersScreenPeriod,
      cdsReportsList: cdsReportsList,
      customerCode: customerCode,
      customerName: customerName,
      customerReportCount: customerReportCount,
      ordersGraph: ordersGraph,
      orderReportsParentScreen: orderReportsParentScreen,
      orderReportsGraph: orderReportsGraph,
      orderReportFilter: orderReportFilter,
      orderReportName: orderReportName,
      orderReportCustomerPlant: orderReportCustomerPlant,
      orderReportDuration: orderReportDuration,
      orderReportChartType: orderReportChartType,
      orderStatusLinkCds: orderStatusLinkCds,
      orderStatusFilterCds: orderStatusFilterCds,
      orderStatusDownloadCds: orderStatusDownloadCds,
      orderHistoryLinkCds: orderHistoryLinkCds,
      orderHistoryFilterCds: orderHistoryFilterCds,
      orderHistoryDownloadCds: orderHistoryDownloadCds,
    };
    dispatch(saveTourDetails(tourDetails));
  };
};

export default Handletour;
