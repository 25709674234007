import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, theme } from 'antd';
import PulseLoader from 'react-spinners/PulseLoader';
import BroadcastMsg from '../popup/BroadcastMsg';
import SupportDocuments from '../support-document/SupportDocument';
import Cookies from 'js-cookie';
import moment from 'moment';
import { isLogged } from 'helpers/utils';
import PropTypes from 'prop-types';

const Footer = (props) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [date, setDate] = useState(null);

  const {
    token: { colorText },
  } = theme.useToken();

  const { broadcast, defaultAccess, portalVersion, hostName } = props;

  useEffect(() => {
    if (broadcast) {
      broadcast.map((data) => {
        if (
          data.flag &&
          Cookies.get(`${defaultAccess}-broadcast`) == undefined
        ) {
          Cookies.set(`${defaultAccess}-broadcast`, defaultAccess, {
            expires: data.timeInterval / 36000,
          });
          setVisible(true);
          setMessage(data.message);
        }
      });
    }
  }, [broadcast, defaultAccess]);

  const tick = () => {
    setDate(moment().utc(true).format('DD/MM/YYYY hh:mm:ss'));
  };

  useEffect(() => {
    let timer = setInterval(tick, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Layout.Footer style={{ color: colorText }}>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 6 }}
              style={{ textAlign: 'center' }}>
              <div className='footer-font-size'>
                {hostName ? hostName.substr(0, 20) : ''}
                {(() => {
                  if (isLogged()) {
                    return ` - ${date || ''} - Version ${portalVersion}`;
                  }
                })()}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 9 }}
              style={{ textAlign: 'center' }}>
              <div className='copyright footer-font-size'>
                {props.isLoading && (
                  <PulseLoader size={6} color={'#FFFFFF'} loading={true} />
                )}
                {props.copyRights ? props.copyRights : null}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 4 }}
              style={{ textAlign: 'center' }}>
              {defaultAccess !== 'SSP' && <SupportDocuments />}
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 5 }}
              style={{ textAlign: 'center' }}>
              <div className='footer-font-size'>
                <Link to='/helpdesk' target='_blank'>
                  {' '}
                  Help Desk
                </Link>
              </div>
            </Col>
          </Row>
          <BroadcastMsg
            visibility={visible}
            broadcastMsg={message}
            handleVisiblity={(data) => setVisible(data)}
          />
        </Layout.Footer>
      </Col>
    </Row>
  );
};

Footer.propTypes = {
  broadcast: PropTypes.array,
  defaultAccess: PropTypes.string,
  portalVersion: PropTypes.string,
  hostName: PropTypes.string,
  isLoading: PropTypes.bool,
  copyRights: PropTypes.string,
};

export default Footer;
