import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tour, Button } from 'antd';
import TourActionButton from '../TourActionButton';
import { destroyTabs } from '../../layout/redux/modules/layout';
import { setWalkthrough } from '../redux/tour';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';

const Helpwalkpc = (props) => {
  const [open, setOpen] = useState(false);
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);

  useEffect(() => {
    Cookie.set('walkthroughParts', 'Yes');
    Cookie.set('walkthroughFc', 'Yes');
  }, [tourReferences]);

  const dispatch = useDispatch();

  const openTour = () => {
    props.handleVisiblity();
    dispatch(destroyTabs());
    setOpen(true);
  };

  const closeTour = () => {
    dispatch(setWalkthrough('PC', 'dashboard', 'Skip'));
    setOpen(false);
  };

  const steps = [
    {
      title: 'Partner Connect',
      description: (
        <div>
          Welcome to Agility Candidates Portal! We put this short tour together
          to show you some key features. You can dismiss this message and come
          back to it at any time using the Support Link.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: null,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          Please click the Parts Menu item to proceed with the next tip.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.pcParts.current,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          The Support area has documentation for PC, video help and contact
          information to seek further system help.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSupportDoc.current,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          Press this Excel Download button to download the latest MS Excel
          template. All the functionality of the portal is available in the
          Excel Download template. You need only to download this template once
          (not every time you wish to see the PC data).
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.excelDownload.current,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          If you request a download from ACP of greater than 1500 rows, the
          system will download the data in the backend and place the downloaded
          data here. You will receive an email when the download is complete
          which will point you back to this area.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.exportHistoryCdsTour.current,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          Use the profile section to change your download text delimiter
          Parameter and to change your password when necessary.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSpaceTour.current,
    },
    {
      title: 'Partner Connect',
      description: (
        <div>
          Thanks for taking the tour! If you want to see this tour again, please
          look to the link in the support section.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSupportDoc.current,
    },
  ];

  return (
    <div>
      <Button type='primary' className='left-walkthrough' onClick={openTour}>
        Take a tour!
      </Button>
      <Tour open={open} onClose={closeTour} steps={steps} />
    </div>
  );
};

Helpwalkpc.propTypes = {
  handleVisiblity: PropTypes.func,
};

export default Helpwalkpc;
