import Cookie from 'js-cookie';
import store from '../../../../redux/store';

const PC_DASHBOARD_COLS = 'pc/dashboard/PC_DASHBOARD_COLS';
const PC_DASHBOARD_COLS_SUCCESS = 'pc/dashboard/PC_DASHBOARD_COLS_SUCCESS';
const PC_DASHBOARD_COLS_FAIL = 'pc/dashboard/PC_DASHBOARD_COLS_FAIL';

const PC_DASHBOARD_ROWS = 'pc/dashboard/PC_DASHBOARD_ROWS';
const PC_DASHBOARD_ROWS_SUCCESS = 'pc/dashboard/PC_DASHBOARD_ROWS_SUCCESS';
const PC_DASHBOARD_ROWS_FAIL = 'pc/dashboard/PC_DASHBOARD_ROWS_FAIL';

export default function reducer(state = {}, action = null) {
  switch (action.type) {
    case PC_DASHBOARD_COLS:
      return { ...state, isLoadingCols: true, columns: [] };
    case PC_DASHBOARD_COLS_SUCCESS:
      return { ...state, isLoadingCols: false, columns: action.payload };
    case PC_DASHBOARD_COLS_FAIL:
      return { ...state, isLoadingCols: false, error: action.payload };
    case PC_DASHBOARD_ROWS:
      return { ...state, isLoadingRows: true, rows: [] };
    case PC_DASHBOARD_ROWS_SUCCESS:
      return { ...state, isLoadingRows: false, rows: action.payload };
    case PC_DASHBOARD_ROWS_FAIL:
      return { ...state, isLoadingRows: false, error: action.payload };
    default:
      return state;
  }
}

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

function getColumns(view) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_DASHBOARD_COLS });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_DASHBOARD_COLUMNS',
            view: view !== 'plant' && view !== 'total' ? 'region' : view,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: PC_DASHBOARD_COLS_SUCCESS,
            payload: data.result,
          });

          dispatch(getRows(view));
        } else {
          dispatch({
            type: PC_DASHBOARD_COLS_FAIL,
            payload: 'Error in loading dashboard columns!',
          });
        }
      });
  };
}

function getRows(view) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_DASHBOARD_ROWS });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_DASHBOARD_ROWS',
            details: {
              email: Cookie.get('email'),
              ...getQsProps('dashboard'),
            },
            view,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: PC_DASHBOARD_ROWS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: PC_DASHBOARD_ROWS_FAIL,
            payload: 'Error in loading dashboard rows!',
          });
        }
      });
  };
}

export { getColumns, getRows };
