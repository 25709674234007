import { message } from 'antd';
import { isObject } from 'helpers/utils';
import Cookie from 'js-cookie';
export const PC_SUPPLIERS_LIST = 'quick-search/PC_SUPPLIERS_LIST';
export const PC_SUPPLIERS_LIST_SUCCESS =
  'quick-search/PC_SUPPLIERS_LIST_SUCCESS';
export const PC_SUPPLIERS_LIST_ERROR = 'quick-search/PC_SUPPLIERS_LIST_ERROR';
export const PC_PLANTS_LIST = 'quick-search/PC_PLANTS_LIST';
export const PC_PLANTS_LIST_SUCCESS = 'quick-search/PC_PLANTS_LIST_SUCCESS';
export const PC_PLANTS_LIST_ERROR = 'quick-search/PC_PLANTS_LIST_ERROR';
export const PC_SELECTED_DATA = 'quick-search/PC_SELECTED_DATA';
export const PC_QS_BULK_LIST_UPDATE_SUCCESS =
  'quick-search/PC_QS_BULK_LIST_UPDATE_SUCCESS';
export const PC_QS_LIST_NAVIGATION = 'quick-search/PC_QS_LIST_NAVIGATION';
export const PC_SAVED_SEARCH_LIST = 'saved-search/PC_SAVED_SEARCH_LIST';
export const PC_SAVED_SEARCH_LIST_SUCCESS =
  'saved-search/PC_SAVED_SEARCH_LIST_SUCCESS';
export const PC_SAVED_SEARCH_LIST_ERROR =
  'saved-search/PC_SAVED_SEARCH_LIST_ERROR';
export const PC_SS_DETAILS = 'saved-search/PC_SS_DETAILS';
export const PC_SS_DETAILS_SUCCESS = 'saved-search/PC_SS_DETAILS_SUCCESS';
export const PC_SS_DETAILS_ERROR = 'saved-search/PC_SS_DETAILS_ERROR';
export const PC_SS_DELETE_UPDATE = 'saved-search/PC_SS_DELETE_UPDATE';
export const PC_RESET_SEARCH = 'search/PC_RESET_SEARCH';
export const PC_SAVED_SEARCH_CREATE = 'saved-search/PC_SAVED_SEARCH_CREATE';
export const PC_SAVED_SEARCH_CREATE_SUCCESS =
  'saved-search/PC_SAVED_SEARCH_CREATE_SUCCESS';
export const PC_SAVED_SEARCH_CREATE_ERROR =
  'saved-search/PC_SAVED_SEARCH_CREATE_ERROR';

export const PC_QS_MODAL_APPLIED = 'quick-search/PC_QS_MODAL_APPLIED';
export const PC_QS_APPLIED = 'quick-search/PC_QS_APPLIED';
export const PC_QS_ADD_VIEW_TYPE = 'quick-search/PC_QS_ADD_VIEW_TYPE';
export const PC_QS_RESET_PART_DETAILS = 'quick-search/PC_QS_RESET_PART_DETAILS';

export const PC_CLOSE_GLOBAL_SEARCH = 'global-search/PC_CLOSE_GLOBAL_SEARCH';

const initialState = {
  quickSearch: {
    dashboard: {},
    newTab0: {},
    newTab1: {},
    newTab2: {},
    newTab3: {},
    newTab4: {},
    newTab5: {},
  },
  savedSearch: {
    dashboard: {},
    newTab0: {},
    newTab1: {},
    newTab2: {},
    newTab3: {},
    newTab4: {},
    newTab5: {},
  },
};

export const getPlantsList = (masterData) => {
  let plantList = new Set([]);
  if (masterData.length) {
    masterData.map((data) => plantList.add(data.customer_plant));
  }

  return plantList;
};

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case PC_SUPPLIERS_LIST:
      return {
        ...state,
        loadingSuppliers: true,
        suppliersList: [],
      };
    case PC_SUPPLIERS_LIST_SUCCESS:
      return {
        ...state,
        suppliersList: action.payload,
        loadingSuppliers: false,
      };
    case PC_SUPPLIERS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingSuppliers: false,
      };
    case PC_PLANTS_LIST:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            loadingPlants: true,
            customerPlants: [],
            reset: false,
          },
        },
      };
    case PC_PLANTS_LIST_SUCCESS: {
      const names = getSupplierDetailsByKey(
        state.suppliersList,
        action.payload,
        'customer_plant',
        'supplier_name'
      );

      const codes = getSupplierDetailsByKey(
        state.suppliersList,
        [...names],
        'supplier_name',
        'supplier_code'
      );

      const sites = getSupplierDetailsByKey(
        state.suppliersList,
        [...codes],
        'supplier_code',
        'site_name'
      );

      const depots = getSupplierDetailsByKey(
        state.suppliersList,
        [...sites],
        'site_name',
        'supplier_key'
      );

      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            customerPlants: action.payload,
            supplierNameList: names,
            supplierCodeList: codes,
            supplierSiteList: sites,
            supplierDepotList: depots,
            loadingPlants: false,
          },
        },
      };
    }
    case PC_PLANTS_LIST_ERROR:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            loadingPlants: false,
            plantsError: action.error,
            customerPlants: [],
          },
        },
      };
    case PC_SELECTED_DATA:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            [action.propToUpdate]: action.payload,
            reset: false,
          },
        },
      };
    case PC_QS_BULK_LIST_UPDATE_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            ...action.payload,
          },
        },
      };
    case PC_SAVED_SEARCH_LIST:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: true,
            savedList: [],
          },
        },
      };
    case PC_SAVED_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: false,
            savedList: action.payload,
          },
        },
      };
    case PC_SAVED_SEARCH_LIST_ERROR:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedListLoading: false,
          },
        },
      };
    case PC_SS_DELETE_UPDATE:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedList: state.savedSearch[action.tabKey].savedList.filter(
              (data) => data.id !== action.searchId
            ),
          },
        },
      };
    case PC_RESET_SEARCH: {
      const { customerPlants, loadingPlants, qsModalApplied } =
        state[action.searchType][action.tabKey];
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            customerPlants,
            loadingPlants,
            qsModalApplied,
            reset: true,
          },
        },
      };
    }
    case PC_SAVED_SEARCH_CREATE:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            creatingSavedSearch: true,
          },
        },
      };
    case PC_SAVED_SEARCH_CREATE_SUCCESS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            savedList: [
              ...(state[action.searchType][action.tabKey]['savedList'] || {}),
              ...action.savedList,
            ],
          },
        },
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            creatingSavedSearch: false,
            ...action.payload,
          },
        },
      };
    case PC_SAVED_SEARCH_CREATE_ERROR:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            creatingSavedSearch: false,
            error: action.payload,
          },
        },
      };
    case PC_SS_DETAILS:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            applyingSavedSearch: true,
          },
        },
      };
    case PC_SS_DETAILS_SUCCESS:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            applyingSavedSearch: false,
            ...action.payload,
            qsModalApplied: true,
            defaultValuesApplied: true,
          },
        },
      };
    case PC_SS_DETAILS_ERROR:
      return {
        ...state,
        quickSearch: {
          ...state.quickSearch,
          [action.tabKey]: {
            applyingSavedSearch: false,
            error: action.payload,
          },
        },
      };
    case PC_QS_LIST_NAVIGATION: {
      const name = getSupplierDetailsByKey(
        state.suppliersList,
        action.plantsList,
        'customer_plant',
        'supplier_name'
      );

      const code = getSupplierDetailsByKey(
        state.suppliersList,
        [...name],
        'supplier_name',
        'supplier_code'
      );
      const site = getSupplierDetailsByKey(
        state.suppliersList,
        [...code],
        'supplier_code',
        'site_name'
      );
      const depot = getSupplierDetailsByKey(
        state.suppliersList,
        [...site],
        'site_name',
        'supplier_key'
      );

      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            ...action.screenData,
            supplierNameList: name,
            supplierCodeList: code,
            supplierSiteList: site,
            supplierDepotList: depot,
            defaultValuesApplied: true,
          },
        },
      };
    }
    case PC_QS_MODAL_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            qsModalApplied: true,
          },
        },
      };
    case PC_QS_ADD_VIEW_TYPE:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            viewType: action.viewType,
          },
        },
      };
    case PC_QS_APPLIED:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            qsApplied: true,
          },
        },
      };
    case PC_QS_RESET_PART_DETAILS:
      return {
        ...state,
        [action.searchType]: {
          ...state[action.searchType],
          [action.tabKey]: {
            ...state[action.searchType][action.tabKey],
            partDetails: [],
          },
        },
      };
    case PC_CLOSE_GLOBAL_SEARCH:
      return {
        ...state,
        searchProps: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

function getSupplierDetails(view) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SUPPLIERS_LIST, tabKey: view });
    return api
      .post(
        'pc',
        formatParams({ type: 'PC_QS_SUPPLIER_DETAILS', view }, getState)
      )
      .then((response) => {
        const { data } = response;

        if (data) {
          dispatch({
            type: PC_SUPPLIERS_LIST_SUCCESS,
            payload: data.result,
            tabKey: view,
          });
        }
      })
      .catch((errors) =>
        dispatch({ type: PC_SUPPLIERS_LIST_ERROR, errors, tabKey: view })
      );
  };
}

function getPlantDetails(tabKey, view, searchType, screenData = {}) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_PLANTS_LIST, tabKey, searchType });
    return api
      .post('pc', formatParams({ type: 'PC_QS_PLANTS', view }, getState))
      .then((response) => {
        const { data } = response;

        if (data) {
          dispatch({
            type: PC_PLANTS_LIST_SUCCESS,
            tabKey,
            payload: data.result,
            searchType,
          });

          if (screenData?.navigation) {
            dispatch({
              type: PC_QS_LIST_NAVIGATION,
              tabKey,
              screenData,
              searchType,
              plantsList: data.result,
            });
          }
        }
      })
      .catch((errors) =>
        dispatch({ type: PC_PLANTS_LIST_ERROR, errors, tabKey, searchType })
      );
  };
}

function setSelectedData(data, tabKey, propToUpdate, searchType) {
  return (dispatch) =>
    dispatch({
      type: PC_SELECTED_DATA,
      payload: data,
      tabKey,
      propToUpdate,
      searchType,
    });
}

function updateListBulk(data, tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_QS_BULK_LIST_UPDATE_SUCCESS,
      payload: data,
      tabKey,
      searchType,
    });
  };
}

const excludeAll = (d) => {
  if (typeof d === 'string') {
    return [d];
  }
  return d ? d.filter((val) => val !== 'all') : [];
};

function formatQsProps(data, camelCase = true) {
  const {
    customerPlant,
    supplierName,
    supplierCode,
    supplierSite,
    supplierDepot,
    agilityType,
    searchTerm,
  } = data;

  if (camelCase) {
    return {
      customerPlant: excludeAll(customerPlant),
      supplierName: excludeAll(supplierName),
      supplierCode: excludeAll(supplierCode),
      supplierSite: excludeAll(supplierSite),
      supplierDepot: excludeAll(supplierDepot),
      agilityType: excludeAll(agilityType),
      searchTerm: searchTerm || '',
    };
  }

  return {
    customer_plant: customerPlant ? customerPlant.join() : '',
    supplier_name: supplierName ? supplierName.join() : '',
    supplier_code: supplierCode ? supplierCode.join() : '',
    supplier_site: supplierSite ? supplierSite.join() : '',
    supplier_key: supplierDepot ? supplierDepot.join() : '',
    agility_type: agilityType ? agilityType.join() : '',
    search_term: searchTerm || '',
  };
}

function getPartDetails(searchTerm, view, tabKey, searchType, viewType) {
  return (dispatch, getState, { api, formatParams }) => {
    const {
      pc: { search: s },
    } = getState();
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      reset,
      ...tabData
    } = s[searchType][tabKey] || {};
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_QS_PARTS_SEARCH',
            view,
            viewType,
            ...formatQsProps({ ...tabData, searchTerm }),
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          let options = data.result.map((option) => ({
            label: option,
            value: option,
          }));
          dispatch(setSelectedData(options, tabKey, 'partDetails', searchType));
        } else {
          dispatch(setSelectedData([], tabKey, 'partDetails', searchType));
        }
      });
  };
}

const getAgilityTypes = (view, tabKey, searchType) => {
  return (dispatch, getState, { api, formatParams }) => {
    const {
      pc: { search: s },
    } = getState();
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      reset,
      ...tabData
    } = s[searchType][tabKey] || {};
    return api
      .post(
        'pc',
        formatParams(
          { type: 'QS_AGILITY_TYPE', view, ...formatQsProps(tabData) },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          dispatch(
            setSelectedData(data.result, tabKey, 'agilityTypesList', searchType)
          );
        }
      });
  };
};

function getSavedList(view, tabKey, searchType) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SAVED_SEARCH_LIST, tabKey, searchType });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_SAVED_SEARCH_LIST',
            view,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data?.result?.length) {
          dispatch({
            type: PC_SAVED_SEARCH_LIST_SUCCESS,
            payload: data.result,
            tabKey,
            searchType,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PC_SAVED_SEARCH_LIST_ERROR,
          payload: error,
          tabKey,
          searchType,
        });
      });
  };
}

function fetchSavedDetails(searchId, view, tabKey, searchType) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SS_DETAILS, view, tabKey, searchType });
    return api
      .post(
        'pc',
        formatParams(
          { type: 'PC_SAVED_SEARCH_DETAILS', view, searchId },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.result && isObject(data.result)) {
          dispatch({
            type: PC_SS_DETAILS_SUCCESS,
            payload: data.result,
            tabKey,
            searchType,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: PC_SS_DETAILS_ERROR,
          payload: error,
          tabKey,
          searchType,
        });
      });
  };
}

function updateSavedList(searchId, tabKey, searchType) {
  return (dispatch) =>
    dispatch({ type: PC_SS_DELETE_UPDATE, searchId, tabKey, searchType });
}

function saveSearch(name, view, searchData, tabKey, searchType, t) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: PC_SAVED_SEARCH_CREATE });
    const {
      customerPlants,
      agilityTypesList,
      supplierCodeList,
      supplierDepotList,
      supplierNameList,
      supplierSiteList,
      loadingPlants,
      savedList,
      savedListLoading,
      reset,
      ...tabData
    } = searchData;
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'PC_SAVE_SEARCH',
            view,
            name,
            ...formatQsProps(tabData),
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200') {
          message.success(t('search_created_and_applied_successfully'));
          dispatch({
            type: PC_SAVED_SEARCH_CREATE_SUCCESS,
            tabKey,
            searchType,
            payload: searchData,
            savedList: [data.result],
          });
        } else {
          dispatch({
            type: PC_SAVED_SEARCH_CREATE_ERROR,
            tabKey,
            searchType,
            payload: 'Error in creating new search. Please try again!',
          });
        }
      })
      .catch((_error) => {
        message.error(t('something_went_wrong'));
      });
  };
}

function deleteSearch(searchId, view, callback, tabKey, searchType, t) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          { type: 'PC_SAVED_SEARCH_DELETE', view, searchId },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200') {
          message.success(t('search_criteria_deleted_successfully'));
          dispatch(updateSavedList(searchId, tabKey, searchType));
          callback(true);
        }
      })
      .catch((_error) => {
        message.error(t('something_went_wrong'));
      });
  };
}

function resetSearch(tabKey, searchType) {
  return (dispatch) => {
    dispatch({
      type: PC_RESET_SEARCH,
      tabKey,
      searchType,
    });
  };
}

const getSupplierDetailsByKey = (
  suppliersList,
  dataList,
  filterKey,
  resultKey
) => {
  let values = new Set([]);
  if (dataList.length) {
    dataList.map((data) => {
      const filteredData = suppliersList.filter(
        (plant) => plant[filterKey] === data
      );
      if (filteredData.length) {
        filteredData.map((d) => values.add(d[resultKey]));
      }
    });
  }

  return values;
};

const setQsModalApplied = (tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_MODAL_APPLIED, tabKey, searchType });
  };
};

const setQsApplied = (tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_APPLIED, tabKey, searchType });
  };
};

const updateQsViewType = (viewType, tabKey, searchType) => {
  return (dispatch) => {
    dispatch({ type: PC_QS_ADD_VIEW_TYPE, viewType, tabKey, searchType });
  };
};

const resetPartDetails = (tabKey) => {
  return (dispatch) => {
    dispatch({
      type: PC_QS_RESET_PART_DETAILS,
      tabKey,
      searchType: 'quickSearch',
    });
  };
};

const setSearchCloseProps = (data) => {
  return (dispatch) => {
    dispatch({
      type: PC_CLOSE_GLOBAL_SEARCH,
      payload: data,
    });
  };
};

export {
  getSupplierDetails,
  getPlantDetails,
  setSelectedData,
  getPartDetails,
  getAgilityTypes,
  updateListBulk,
  getSavedList,
  fetchSavedDetails,
  deleteSearch,
  resetSearch,
  saveSearch,
  getSupplierDetailsByKey,
  formatQsProps,
  setQsModalApplied,
  updateQsViewType,
  setQsApplied,
  resetPartDetails,
  setSearchCloseProps,
};
