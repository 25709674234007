import { message } from 'antd';
import Cookie from 'js-cookie';
import moment from 'moment';

import store from '../../../../../redux/store';
import service from '../../../../../service/Service';
import { getLastRowIndex, getUUID, isObject } from 'helpers/utils';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';

export const NEWBUYS_LOV = 'pc/newbuys/NEWBUYS_LOV';
export const NEWBUYS_LOV_SUCCESS = 'pc/newbuys/NEWBUYS_LOV_SUCCESS';
export const NEWBUYS_LOV_ERROR = 'pc/newbuys/NEWBUYS_LOV_ERROR';

export const NEWBUYS_OPEN_COLUMNS = 'pc/newbuys/NEWBUYS_OPEN_COLUMNS';
export const NEWBUYS_OPEN_COLUMNS_SUCCESS =
  'pc/newbuys/NEWBUYS_OPEN_COLUMNS_SUCCESS';
export const NEWBUYS_OPEN_COLUMNS_FAIL = 'pc/newbuys/NEWBUYS_OPEN_COLUMNS_FAIL';

export const NEWBUYS_CLOSED_COLUMNS = 'pc/newbuys/NEWBUYS_CLOSED_COLUMNS';
export const NEWBUYS_CLOSED_COLUMNS_SUCCESS =
  'pc/newbuys/NEWBUYS_CLOSED_COLUMNS_SUCCESS';
export const NEWBUYS_CLOSED_COLUMNS_FAIL =
  'pc/newbuys/NEWBUYS_CLOSED_COLUMNS_FAIL';

export const NEWBUYS_HISTORY_COLUMNS = 'pc/newbuys/NEWBUYS_HISTORY_COLUMNS';
export const NEWBUYS_HISTORY_COLUMNS_SUCCESS =
  'pc/newbuys/NEWBUYS_HISTORY_COLUMNS_SUCCESS';
export const NEWBUYS_HISTORY_COLUMNS_FAIL =
  'pc/newbuys/NEWBUYS_HISTORY_COLUMNS_FAIL';

export const NEWBUYS_OPEN_ROWS_INFO = 'pc/newbuys/NEWBUYS_OPEN_ROWS_INFO';
export const NEWBUYS_CLOSED_ROWS_INFO = 'pc/newbuys/NEWBUYS_CLOSED_ROWS_INFO';
export const NEWBUYS_HISTORY_ROWS_INFO = 'pc/newbuys/NEWBUYS_HISTORY_ROWS_INFO';

export const NEWBUYS_SUBMIT = 'pc/newbuys/NEWBUYS_SUBMIT';
export const NEWBUYS_SUBMIT_SUCCESS = 'pc/newbuys/NEWBUYS_SUBMIT_SUCCESS';
export const NEWBUYS_SUBMIT_FAIL = 'pc/newbuys/NEWBUYS_SUBMIT_FAIL';

export const DOCS_STD_SUCCESS = 'pc/newbuys/DOCS_STD_SUCCESS';

export const DESTROY_NEWBUYS_BY_KEY = 'pc/newbuys/DESTROY_NEWBUYS_BY_KEY';

export const SET_FOCUSED_CELL_EDITOR = 'pc/newbuys/SET_FOCUSED_CELL_EDITOR';

export const SET_ERROR_NODES = 'pc/newbuys/SET_ERROR_NODES';
export const SET_COMMITTED = 'pc/newbuys/SET_COMMITTED';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    newBuysOpenColumns: {},
    newBuysClosedColumns: {},
    newBuysHistoryColumns: {},
    submittingItem: false,
    submittedItem: true,
    exitRowData: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEWBUYS_LOV:
      return {
        ...state,
        newbuysLov: {
          loading: true,
          holidays: {},
          fob: {},
          carrier: {},
          currency: {},
        },
      };
    case NEWBUYS_LOV_SUCCESS:
      return {
        ...state,
        newbuysLov: {
          loading: false,
          ...action.payload,
        },
      };
    case NEWBUYS_LOV_ERROR:
      return {
        ...state,
        newbuysLov: {
          loading: false,
          error: action.payload,
        },
      };
    case NEWBUYS_OPEN_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            loading: true,
          },
        },
      };
    case NEWBUYS_OPEN_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...state[action.tabKey]['open'],
            loading: false,
            newBuysOpenColumns: action.payload,
          },
        },
      };
    case NEWBUYS_OPEN_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...state[action.tabKey]['open'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case NEWBUYS_OPEN_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...(state[action.tabKey]['open']
              ? state[action.tabKey]['open']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case NEWBUYS_CLOSED_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            loading: true,
          },
        },
      };
    case NEWBUYS_CLOSED_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...state[action.tabKey]['closed'],
            loading: false,
            newBuysClosedColumns: action.payload,
          },
        },
      };
    case NEWBUYS_CLOSED_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...state[action.tabKey]['closed'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case NEWBUYS_CLOSED_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          closed: {
            ...(state[action.tabKey]['closed']
              ? state[action.tabKey]['closed']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case NEWBUYS_HISTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            loading: true,
          },
        },
      };
    case NEWBUYS_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...state[action.tabKey]['history'],
            loading: false,
            newBuysHistoryColumns: action.payload,
          },
        },
      };
    case NEWBUYS_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...state[action.tabKey]['history'],
            loading: false,
            error: action.payload,
          },
        },
      };
    case NEWBUYS_HISTORY_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          history: {
            ...(state[action.tabKey]['history']
              ? state[action.tabKey]['history']
              : {}),
            rowsInfo: action.payload,
          },
        },
      };
    case NEWBUYS_SUBMIT:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: true,
        },
      };
    case NEWBUYS_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          submittedItem: true,
        },
      };
    case NEWBUYS_SUBMIT_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          error: action.payload,
        },
      };
    case DESTROY_NEWBUYS_BY_KEY:
      delete state[action.tabKey];
      return state;
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          focusedCell: {
            colId: action.colId,
            rowIndex: action.rowIndex,
          },
        },
      };
    case SET_ERROR_NODES:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...(state[action.tabKey]['open']
              ? state[action.tabKey]['open']
              : {}),
            errorNodes: action.errorNodes,
          },
        },
      };
    case SET_COMMITTED:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          open: {
            ...(state[action.tabKey]['open']
              ? state[action.tabKey]['open']
              : {}),
            commit: action.commit,
          },
        },
      };
    default:
      return state;
  }
}

const getNbsState = (type, tabKey) => {
  const reduxState = store.getState();
  const {
    pc: {
      newbuys: {
        [tabKey]: { [type]: open },
      },
    },
  } = reduxState;
  return open;
};

const getSplitRows = (rowData, parentId) => {
  const splitDataSize = rowData.length;
  const totalSplitSize = 5;
  const emptyObjectSize = totalSplitSize - splitDataSize;
  const emptyObjArr = [];
  let splitId = 1;

  let existingSplitData = [];
  if (rowData.length) {
    existingSplitData = rowData.map((row) => {
      const splitIdIncr = splitId++;
      return {
        ...row,
        id: `${parentId}_${splitIdIncr}`,
        originalValues: { ...row },
        parentId,
      };
    });
  }

  if (emptyObjectSize) {
    for (let i = 0; i < emptyObjectSize; i++) {
      const splitIdIncr = splitId++;
      emptyObjArr.push({
        originalValues: {},
        id: `${parentId}_${splitIdIncr}`,
        parentId,
      });
    }
  }

  return [...existingSplitData, ...emptyObjArr];
};

const handleNoRowsOverlay = (rowData, params) => {
  const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
  if (!rowData.length && params.request.startRow < rowsPerRequest) {
    params.api.showNoRowsOverlay();
  }
};

const getGridSuccessResponse = (rowData, params) => {
  const doingInfinite = !params.request.groupKeys.length;
  return doingInfinite
    ? {
        rowData: rowData,
        rowCount: getLastRowIndex(params.request, rowData),
      }
    : { rowData: rowData };
};

const getEndRow = (data, params) =>
  params.request.endRow > data.result.lastRow
    ? data.result.lastRow
    : params.request.endRow;

const isReachedLastRow = (lastRow, endRowNew) =>
  lastRow > 0 && endRowNew > lastRow;

const isInboundSplit = (node) => {
  return node.data.has_split_data;
};

const getInboundSplitErrors = (errorRecords) => {
  return errorRecords.filter((er) => !er.data?.originalValues?.splitCreated);
};

const getCreatedSplitErrors = (errorRecords) => {
  return errorRecords.filter((er) => er.data?.originalValues?.splitCreated);
};

const getErrorNodes = (parentNode) => parentNode.data.splitErrorRecords || [];

const getErrorRecords = (errorNodes) =>
  errorNodes.length ? errorNodes.map((en) => en.data) : [];

const handleInboundSplitErrors = (inboundSplitErrors, outArr) => {
  if (inboundSplitErrors.length) {
    inboundSplitErrors.forEach((ise) =>
      outArr.splice(ise.data.split_id - 1, 1, ise.data)
    );
  }
};

const handleCreatedSplitErrors = (createdSplitErrors, rowData, outArr) => {
  if (createdSplitErrors.length) {
    let insertPosition = rowData.length + 1;
    createdSplitErrors.forEach((cse) =>
      outArr.splice(insertPosition++, 1, cse)
    );
  }
};

const isParentNodeHasValue = (parentNode) =>
  parentNode && Object.getOwnPropertyNames(parentNode).length;

const getParentNode = (nbsState, parentId) => {
  let parentNode = {};
  if (nbsState?.errorNodes?.length) {
    parentNode =
      nbsState.errorNodes.find((en) => en.data.id === parentId) || {};
  }
  return parentNode;
};

const getSplitRowsResultEmpty = (
  emptyObjectSize,
  splitId,
  parentId,
  callBack
) => {
  if (emptyObjectSize) {
    for (let i = 0; i < emptyObjectSize; i++) {
      const splitIdIncr = splitId++;
      callBack({
        originalValues: {},
        id: `${parentId}_${splitIdIncr}`,
        split_id: splitIdIncr,
        parentId: parentId,
      });
    }
  }
};

const getSplitRowsResult = (rowData, emptyObjectSize, splitId, parentId) => {
  let outArr = [];
  if (rowData.length) {
    rowData.forEach((rd) => {
      const splitIdIncr = splitId++;
      outArr.push({
        ...rd,
        id: `${parentId}_${splitIdIncr}`,
        originalValues: { ...rd },
        split_id: splitIdIncr,
        parentId: parentId,
      });
    });
  }

  getSplitRowsResultEmpty(emptyObjectSize, splitId, parentId, (d) =>
    outArr.push(d)
  );

  return outArr;
};

const getSplitRowsOpen = (rowData, parentId, tabKey = null) => {
  const splitDataSize = rowData.length;
  const totalSplitSize = 5;
  const emptyObjectSize = totalSplitSize - splitDataSize;
  let splitId = 1;

  // Restore old values
  const nbsState = getNbsState('open', tabKey);
  let parentNode = getParentNode(nbsState, parentId);

  const createSplitRows = (errorRecords) => {
    const errRecordsLength = errorRecords.length;
    const emptyObjSize = totalSplitSize - errRecordsLength;
    let outArr = [];
    if (errRecordsLength) {
      errorRecords.forEach((errorRecord) => {
        const splitIdIncr = splitId++;
        outArr.push({
          ...errorRecord,
          id: `${parentId}_${splitIdIncr}`,
          originalValues: { ...errorRecord },
          split_id: splitIdIncr,
          parentId: parentId,
        });
      });
    }

    if (emptyObjSize) {
      for (let i = 0; i < emptyObjSize; i++) {
        const splitIdIncr = splitId++;
        outArr.push({
          originalValues: {},
          id: `${parentId}_${splitIdIncr}`,
          split_id: splitIdIncr,
          parentId: parentId,
        });
      }
    }

    return outArr;
  };

  const handleParentNode = () => {
    let outArr = [];
    const errorNodes = getErrorNodes(parentNode);
    const errorRecords = getErrorRecords(errorNodes);
    const createInboundSplitRows = () => {
      const inboundSplitErrors = getInboundSplitErrors(errorRecords);
      const createdSplitErrors = getCreatedSplitErrors(errorRecords);
      outArr = getSplitRowsResult(rowData);
      handleInboundSplitErrors(inboundSplitErrors, outArr);
      handleCreatedSplitErrors(createdSplitErrors, rowData, outArr);
      return outArr;
    };
    const isInboundSplitNode = isInboundSplit(parentNode);
    if (isInboundSplitNode) {
      return createInboundSplitRows();
    } else {
      return createSplitRows(errorRecords);
    }
  };
  if (isParentNodeHasValue(parentNode)) {
    return handleParentNode();
  } else {
    return getSplitRowsResult(rowData, emptyObjectSize, splitId, parentId);
  }
};

const getView = (viewType, parentId) => {
  return !parentId.length ? viewType : `${viewType}_SPLIT`;
};

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};

  const stringify = (val = '') => {
    if (typeof val !== 'string') {
      const filteredVal = val.filter((data) => data !== 'all');
      return filteredVal.join();
    } else {
      return val;
    }
  };

  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

const getNcnrVal = (ncnr, fullText = false) => {
  if (fullText) {
    return ncnr === 'Y' ? 'Yes' : 'No';
  }
  return ncnr === 'Yes' ? 'Y' : 'N';
};

const getNbsLov = () => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: NEWBUYS_LOV });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'LOV_DATA',
            view: `NBS`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200' && isObject(data.result)) {
          dispatch({ type: NEWBUYS_LOV_SUCCESS, payload: data.result });
        } else {
          dispatch({
            type: NEWBUYS_LOV_ERROR,
            payload: 'Something went wrong!',
          });
        }
      });
  };
};

const getLastRow = (tabKey, view) => {
  const rowInfoState = store.getState().pc.newbuys[view];
  const tabData = rowInfoState ? rowInfoState[tabKey] : false;
  if (tabData) {
    return tabData.rowsInfo.lastRow;
  } else {
    return 0;
  }
};

function getNewBuysOpenColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: NEWBUYS_OPEN_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `NBS_OPEN`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            ...configs
          } = data.result;

          dispatch({
            type: NEWBUYS_OPEN_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: NEWBUYS_OPEN_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const handleErrorNodes = (nbsState, rowData, errorNodeCnt) => {
  if (errorNodeCnt) {
    nbsState.errorNodes.forEach((n) => {
      let findIndex = rowData.findIndex((node) => node.id === n.data.id);
      if (findIndex !== -1) {
        rowData.splice(findIndex, 1);
      }
    });
    rowData.unshift(...nbsState.errorNodes.map((n) => n.data));
  }
};

const getRowDataFromResponseOpen = (data, params, tabKey) => {
  const nbsState = getNbsState('open', tabKey);

  let errorNodeCnt = 0;
  if (nbsState.commit) {
    errorNodeCnt = nbsState.errorNodes.length;
  }

  let rowData = [];
  if (params.request.groupKeys.length) {
    rowData = getSplitRowsOpen(
      data.result.rowData,
      params.request.groupKeys[0],
      tabKey
    );
  } else {
    rowData = data.result.rowData.map((row) => {
      return {
        ...row,
        isEditing: false,
        errors: {},
        hasError: false,
        id: row.request_id,
        group: true,
        ncnr: getNcnrVal(row.ncnr, true),
        originalValues: {
          ...{ ...row, ncnr: getNcnrVal(row.ncnr, true) },
        },
        changedFields: {
          arrival_date_promised: false,
          supplier_price: false,
          supplier_order_qty: false,
          currency: false,
          minimum_order_qty: false,
          mult: false,
          carrier: false,
          ncnr: false,
          correct_mfr: false,
          correct_mpn: false,
        },
      };
    });

    handleErrorNodes(nbsState, rowData, errorNodeCnt);

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: NEWBUYS_OPEN_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  }

  return rowData;
};

function getNewBuysOpenRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'open');
      const endRowNew = params.request.endRow;
      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`NBS_OPEN`, params.request.groupKeys),
                details: {
                  email: Cookie.get('email'),
                  ...getQsProps(tabKey),
                  parentId: params.request.groupKeys.join(),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            const { data } = response;
            if (data?.statusCode === '200') {
              let rowData = getRowDataFromResponseOpen(data, params, tabKey);
              const result = getGridSuccessResponse(rowData, params);
              setTimeout(() => {
                params.success(result);
              }, 200);
              // Hide overlay loading on success
              params.api.hideOverlay();
              params.api.refreshHeader();
              handleNoRowsOverlay(rowData, params);
            } else {
              params.fail();
            }

            return response;
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

function getNewBuysClosedColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: NEWBUYS_CLOSED_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `NBS_CLOSED`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            ...configs
          } = data.result;

          dispatch({
            type: NEWBUYS_CLOSED_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: NEWBUYS_CLOSED_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getRowDataFromResponseClosed = (data, params, tabKey) => {
  let rowData = data.result.rowData;

  if (!params.request.groupKeys.length) {
    rowData = rowData.map((row) => ({
      ...row,
      id: row.request_id,
      group: true,
    }));

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: NEWBUYS_CLOSED_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  } else {
    rowData = getSplitRows(data.result.rowData, params.request.groupKeys[0]);
  }

  return rowData;
};

function getNewBuysClosedRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'closed');
      const endRowNew = params.request.endRow;
      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`NBS_CLOSED`, params.request.groupKeys),
                details: {
                  email: Cookie.get('email'),
                  ...getQsProps(tabKey),
                  parentId: params.request.groupKeys.join(),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            const { data } = response;
            if (data && data.statusCode === '200') {
              let rowDataClosed = getRowDataFromResponseClosed(
                data,
                params,
                tabKey
              );
              const result = getGridSuccessResponse(rowDataClosed, params);
              setTimeout(() => {
                params.success(result);
              }, 200);
              // Hide overlay loading on success
              params.api.hideOverlay();
              handleNoRowsOverlay(rowDataClosed, params);
            } else {
              params.fail();
            }
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

function getNewBuysHistoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: NEWBUYS_HISTORY_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'NBS_HISTORY',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            ...configs
          } = data.result;

          dispatch({
            type: NEWBUYS_HISTORY_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: NEWBUYS_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getRowDataFromResponseHist = (data, params, tabKey) => {
  let rowDataHist = data.result.rowData;
  if (!params.request.groupKeys.length) {
    rowDataHist = rowDataHist.map((histRow) => ({
      ...histRow,
      id: histRow.request_id,
      group: true,
    }));

    const endRow = getEndRow(data, params);

    store.dispatch({
      type: NEWBUYS_HISTORY_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  } else {
    rowDataHist = getSplitRows(
      data.result.rowData,
      params.request.groupKeys[0]
    );
  }

  return rowDataHist;
};

function getNewBuysHistoryRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      // Restrict request if last row reached
      const lastRow = getLastRow(tabKey, 'history');
      const endRowNew = params.request.endRow;
      if (isReachedLastRow(lastRow, endRowNew)) {
        setTimeout(() => {
          params.success({ rowData: [], rowCount: lastRow });
        }, 0);
      } else {
        params.api.showLoadingOverlay();
        return api
          .post(
            'pc',
            formatParams(
              {
                type: 'AG_GRID_ROWS',
                view: getView(`NBS_HISTORY`, params.request.groupKeys),
                details: {
                  email: Cookie.get('email'),
                  ...getQsProps(tabKey),
                  parentId: params.request.groupKeys.join(),
                },
                gridParams: {
                  ...params.request,
                },
              },
              store.getState
            )
          )
          .then((response) => {
            const { data } = response;
            if (data && data.statusCode === '200') {
              const rowDataHist = getRowDataFromResponseHist(
                data,
                params,
                tabKey
              );
              const result = getGridSuccessResponse(rowDataHist, params);
              setTimeout(() => {
                params.success(result);
              }, 200);
              // Hide overlay loading on success
              params.api.hideOverlay();
              handleNoRowsOverlay(rowDataHist, params);
            } else {
              params.fail();
            }
          })
          .catch((_error) => {
            params.fail();
          });
      }
    },
  };
}

const getSupplierAction = (node) => {
  let supplierAction = node.supplier_action;

  const {
    supplier_order_qty,
    supplier_price,
    arrival_date_promised,
    currency,
  } = node.changedFields;

  const isFieldsChanged =
    supplier_order_qty || supplier_price || arrival_date_promised || currency;

  if (isFieldsChanged) {
    if (supplierAction === '3 - Accepted') {
      supplierAction = '4 - Accepted w/ Change';
    }
  } else if (supplierAction === '4 - Accepted w/ Change') {
    supplierAction = '3 - Accepted';
  }

  return supplierAction;
};

const prepareReleaseCommitData = (masterNodes) => {
  let newBuysArray = [];
  masterNodes.forEach((node) => {
    newBuysArray.push({
      customer_plant: node.customer_plant,
      supplier_key: node.supplier_key,
      part: node.item,
      request_id: node.request_id,
      split_id: node.split_id,
      min: node.minimum_order_qty,
      multi: node.mult,
      lt: node.leadtime,
      supplier_lead_time: node.supplier_lead_time,
      mfr: node.correct_mfr,
      mpn: node.correct_mpn,
      effect_end_date: node.effect_end_date,
      qty: node.supplier_order_qty,
      date: node.arrival_date_promised,
      price: node.supplier_price,
      action: getSupplierAction(node),
      reason: node.reason_code,
      comment: node.supplier_comment,
      curr: node.currency,
      sl: node.carrier,
      fob: node.fob,
      ncnr: getNcnrVal(node.ncnr),
      ncnr_comment: node.ncnr_reason,
    });

    let splitcnt = 1;
    node.splitData.forEach((splitNode) => {
      if (splitNode.supplier_order_qty !== undefined) {
        newBuysArray.push({
          customer_plant: node.customer_plant,
          supplier_key: node.supplier_key,
          part: node.item,
          request_id: node.request_id,
          split_id: splitcnt.toString(),
          min: node.minimum_order_qty,
          multi: node.mult,
          lt: node.leadtime,
          supplier_lead_time: node.supplier_lead_time,
          mfr: node.correct_mfr,
          mpn: node.correct_mpn,
          effect_end_date: node.effect_end_date,
          qty: splitNode.supplier_order_qty,
          date: moment(splitNode.arrival_date_promised).format('YYYY-MM-DD'),
          price: node.supplier_price,
          action: node.supplier_action,
          reason: node.reason_code,
          comment: node.supplier_comment,
          curr: node.currency,
          sl: node.carrier,
          fob: node.fob,
          ncnr: getNcnrVal(node.ncnr),
          ncnr_comment: node.ncnr_reason,
        });
        splitcnt++;
      }
    });
  });

  return {
    NewBuyArray: newBuysArray,
  };
};

function submitData(nodes, callback, tabKey, t) {
  let headers = prepareReleaseCommitData(nodes);
  return (dispatch, _getState, { api }) => {
    dispatch({ type: NEWBUYS_SUBMIT, tabKey });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            uuid: getUUID(),
            excel: 'NO',
            source: 'PORTAL',
            txn_type: `NEWBUY`,
            requestor: Cookie.get('email'),
            customer: 'SANM',
          },
          ...headers,
        },
      })
      .then((response) => {
        const { data } = response;
        if (
          data == 1 ||
          data == 'SUCCESS' ||
          (data.TXN && data.TXN[0].return_msg == 'SUCCESS')
        ) {
          callback(true);
          message.success(t('data_saved_successfully'));
        } else {
          callback(false);
          message.error(t('something_went_wrong'));
        }
      });
  };
}

const handleRowsWithSplitAdd = (result, callBack) => {
  result.rowData.forEach((row) => {
    const { splitRecords, ...rest } = row;
    callBack(rest);
    if (splitRecords.length) {
      splitRecords.forEach((splitRow) => callBack(splitRow));
    }
  });
};

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              let rowsWithSplitAdded = [];
              handleRowsWithSplitAdd(result, (d) => rowsWithSplitAdded.push(d));
              exportDTo({
                columnApi,
                fileName: fileName,
                data: rowsWithSplitAdded,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroyNewBuysByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_NEWBUYS_BY_KEY, tabKey });
  };
};

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const setErrorNodes = (errorNodes, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_ERROR_NODES,
      tabKey,
      errorNodes,
    });
  };
};

const setCommitted = (commit, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_COMMITTED,
      tabKey,
      commit,
    });
  };
};

export {
  getNewBuysOpenColumns,
  getNewBuysOpenRows,
  getNewBuysClosedColumns,
  getNewBuysClosedRows,
  getNewBuysHistoryColumns,
  getNewBuysHistoryRows,
  submitData,
  destroyNewBuysByKey,
  getNbsLov,
  setFocusedCell,
  setErrorNodes,
  setCommitted,
};
