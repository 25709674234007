import store from '../../../../redux/store';
import service from '../../../../service/Service';
import { getLastRowIndex, getUUID } from '../../../../helpers/utils';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import selectColumn from '../SelectColObj';
import Cookies from 'js-cookie';
import { message, Modal, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const SUPPLIER_INVENTORY_COLUMNS =
  'pc/inv/supplier-inventory/SUPPLIER_INVENTORY_COLUMNS';
export const SUPPLIER_INVENTORY_COLUMNS_SUCCESS =
  'pc/inv/supplier-inventory/SUPPLIER_INVENTORY_COLUMNS_SUCCESS';
export const SUPPLIER_INVENTORY_COLUMNS_FAIL =
  'pc/inv/supplier-inventory/SUPPLIER_INVENTORY_COLUMNS_FAIL';

export const SUPPLIER_INVENTORY_ROWS_INFO =
  'pc/inv/supplier-inventory/SUPPLIER_INVENTORY_ROWS_INFO';

export const SUPPLIER_INVENTORY_SUBMIT =
  'pc/inv/supplier-inventory/SUPPLIER_INVENTORY_SUBMIT';

export const DESTROY_SUPPLIER_INVENTORY_BY_KEY =
  'pc/inv/supplier-inventory/DESTROY_SUPPLIER_INVENTORY_BY_KEY';
export const UPDATE_SUPPLY_ROW_DATA_MODIFIED_BY_KEY =
  'pc/inv/supplier-inventory/UPDATE_SUPPLY_ROW_DATA_MODIFIED_BY_KEY';

export const SET_FOCUSED_CELL_EDITOR =
  'pc/inv/supplier-inventory/SET_FOCUSED_CELL_EDITOR';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    supplierInventoryColumns: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUPPLIER_INVENTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case SUPPLIER_INVENTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          supplierInventoryColumns: action.payload,
          loaded: true,
        },
      };
    case SUPPLIER_INVENTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
          loaded: true,
        },
      };
    case SUPPLIER_INVENTORY_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          rowsInfo: action.payload,
        },
      };
    case DESTROY_SUPPLIER_INVENTORY_BY_KEY:
      delete state[action.tabKey];
      return state;
    case UPDATE_SUPPLY_ROW_DATA_MODIFIED_BY_KEY:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          isRowDataModified: action.payload,
        },
      };
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          focusedCell: {
            colId: action.colId,
            rowIndex: action.rowIndex,
          },
        },
      };
    default:
      return state;
  }
}

const stringify = (val = '') => {
  if (typeof val !== 'string') {
    const filteredVal = val.filter((data) => data !== 'all');
    return filteredVal.join();
  } else {
    return val;
  }
};

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};
  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    search_term: tabData.searchTerm,
  };
};

function getSupplierInventoryColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SUPPLIER_INVENTORY_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'SUPPLIER_INVENTORY',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let selectColumnModify = {
            ...selectColumn,
            width: 44,
          };
          data.result.columnDefs.unshift(selectColumnModify);
          let configs = data.result;
          dispatch({
            type: SUPPLIER_INVENTORY_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: SUPPLIER_INVENTORY_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

function getSupplierInventoryRows(tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const qsState = store.getState().pc.search.quickSearch;
      const tabData = qsState[tabKey] || {};
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'SUPPLIER_INVENTORY',
              details: {
                email: Cookies.get('email'),
                customer_plant: stringify(tabData.customerPlant),
                supplier_name: stringify(tabData.supplierName),
                supplier_code: stringify(tabData.supplierCode),
                supplier_key: stringify(tabData.supplierDepot),
                agility_type: stringify(tabData.agilityType),
                search_term: tabData.searchTerm,
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
                originalValues: { ...row },
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SUPPLIER_INVENTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
              details: {
                email: Cookies.get('email'),
                ...getQsProps(tabKey),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookies.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const prepareInventoryCommitData = (data) => {
  let commitPartArray = [];

  data.map((d) => {
    if (d.data.smi_type == 'RTF') {
      commitPartArray.push({
        plant: d.data.customer_plant,
        supplier_key: d.data.supplier_key,
        part: d.data.part,
        mfr_name: d.data.manufacturer_name,
        mpn: d.data.mpn,
        total_avil_qty: parseInt(d.data.total_available_inv_qty),
        ats_qty: parseInt(d.data.ats_qty),
        on_order_qty: parseInt(d.data.on_order_qty),
        received_qty: 0,
        consumed_qty: 0,
        on_hold_qty: 0,
        contract_buffer_qty: d.data.contract_buffer_qty,
        min_buff_qty: d.data.min_buff_qty,
        base_weeks: d.data.base_weeks,
        multiplier: d.data.multiplier,
        uom: d.data.uom,
      });
    } else {
      commitPartArray.push({
        plant: d.data.customer_plant,
        supplier_key: d.data.supplier_key,
        part: d.data.part,
        mfr_name: d.data.manufacturer_name,
        mpn: d.data.mpn,
        total_avil_qty: parseInt(d.data.total_available_inv_qty),
        ats_qty: parseInt(d.data.ats_qty),
        on_order_qty: parseInt(d.data.on_order_qty),
        received_qty: parseInt(d.data.received_qty),
        consumed_qty: parseInt(d.data.consumed_qty),
        on_hold_qty: parseInt(d.data.on_hold_qty),
        contract_buffer_qty: d.data.contract_buffer_qty,
        min_buff_qty: d.data.min_buff_qty,
        base_weeks: d.data.base_weeks,
        multiplier: d.data.multiplier,
        uom: d.data.uom,
      });
    }
  });

  return {
    commitPartArray,
  };
};

const handleCommitResponse = (TXN, gridApi, nodes, t) => {
  const overallStatus = TXN[0].return_msg;
  if (overallStatus === 'SUCCESS') {
    message.success(t('data_updated_successfully'));
    //Refresh grid
    gridApi.stopEditing();
    nodes.map((node) => {
      node.setSelected(false);
      node.updateData({
        ...node.data,
        isEditing: false,
        errors: {},
        hasError: false,
      });
    });
    gridApi.refreshServerSide({ purge: true });
    gridApi.refreshCells({ force: true });
  } else {
    const failureResponse = TXN[0].SMIPartArray;
    Modal.error({
      title: t('failed_transactions'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <div
          style={{
            maxHeight: 250,
            minHeight: 150,
            overflowY: 'auto',
          }}>
          <div
            style={{
              borderBottom: '1px solid #CCC',
              padding: 5,
              fontSize: 12,
              fontWeight: 'bold',
            }}>
            <Row>
              <Col style={{ textAlign: 'center' }} span={2} order={1}>
                Plant
              </Col>
              <Col style={{ textAlign: 'center' }} span={6} order={2}>
                Supplier
              </Col>
              <Col style={{ textAlign: 'center' }} span={7} order={3}>
                Part
              </Col>
              <Col style={{ textAlign: 'center' }} span={9} order={4}>
                Error
              </Col>
            </Row>
          </div>
          {failureResponse[0].map((item) => (
            <div
              key={getUUID()}
              style={{
                borderBottom: '1px solid #CCC',
                padding: 5,
                fontSize: 10,
              }}>
              <Row>
                <Col style={{ textAlign: 'center' }} span={2} order={1}>
                  {item.customer_plant}
                </Col>
                <Col style={{ textAlign: 'center' }} span={6} order={2}>
                  {item.supplier_key}
                </Col>
                <Col style={{ textAlign: 'center' }} span={7} order={3}>
                  {item.part}
                </Col>
                <Col
                  style={{
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  span={9}
                  order={4}>
                  <span title={item.error_message}>{item.error_message}</span>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      ),
    });
  }
};

function submitData(nodes, gridApi, tabKey, t) {
  let headers = {};
  if (nodes.length) {
    headers = prepareInventoryCommitData(nodes);
    return (dispatch, _getState, { api }) => {
      dispatch({ type: SUPPLIER_INVENTORY_SUBMIT, tabKey });
      return api
        .post('pcsubmitaction', {
          TXN: {
            Header: {
              txn_type: 'PORTAL846I',
              uuid: getUUID(),
              customer: 'SANM',
              requestor: Cookies.get('email'),
              source: 'PORTAL',
              excel: 'NO',
            },
            SMIPartArray: headers.commitPartArray,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data) {
            const { TXN } = data;
            if (TXN) {
              handleCommitResponse(TXN, gridApi, nodes, t);
            } else {
              message.error(t('something_went_wrong'));
            }
          } else {
            message.error(t('something_went_wrong'));
          }
        });
    };
  }
}

const destroySupplierInventoryByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_SUPPLIER_INVENTORY_BY_KEY, tabKey });
  };
};

const updateSupplyInvRowDataModifiedByKey = (tabKey, isModified) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SUPPLY_ROW_DATA_MODIFIED_BY_KEY,
      payload: isModified,
      tabKey,
    });
  };
};

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

export {
  getSupplierInventoryColumns,
  getSupplierInventoryRows,
  submitData,
  destroySupplierInventoryByKey,
  updateSupplyInvRowDataModifiedByKey,
  setFocusedCell,
};
