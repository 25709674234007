import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import { getLastRowIndex, getUUID } from '../../../../helpers/utils';
import { message, Modal } from 'antd';
import Cookie from 'js-cookie';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';

const PC_FC_DROPDOWN_DATA_LOADING = 'fc/forcast/PC_FC_DROPDOWN_DATA_LOADING';
const PC_FC_DROPDOWN_DATA_SUCCESS = 'fc/forcast/PC_FC_DROPDOWN_DATA_SUCCESS';
const PC_FC_DROPDOWN_DATA_FAIL = 'fc/forcast/PC_FC_DROPDOWN_DATA_FAIL';
export const FC_COLUMNS = 'fc/forcast/FC_COLUMNS';
export const FC_COLUMNS_SUCCESS = 'fc/forcast/FC_COLUMNS_SUCCESS';
export const FC_COLUMNS_FAIL = 'fc/forcast/FC_COLUMNS_FAIL';
export const FC_FB_SUCCESS = 'fc/forcast/FC_FB_SUCCESS';
export const FC_FB_FAIL = 'fc/forcast/FC_FB_FAIL';
export const FC_ROWS_INFO = 'fc/forcast/FC_ROWS_INFO';
export const FC_DATA_SUBMIT = 'pc/fc/FC_DATA_SUBMIT';
export const FC_DATA_SUBMIT_SUCCESS = 'pc/fc/FC_DATA_SUBMIT_SUCCESS';
export const FC_DATA_SUBMIT_FAIL = 'pc/fc/FC_DATA_SUBMIT_FAIL';
export const VIEW_FC_DETAIL_SUCCESS = 'pc/parts/VIEW_FC_DETAIL_SUCCESS';
const COMMENTS_UPDATE = 'pc/forcast/COMMENTS_UPDATE';
const COMMENTS_UPDATE_SUCCESS = 'pc/forcast/COMMENTS_UPDATE_SUCCESS';
const COMMENTS_UPDATE_FAIL = 'pc/forcast/COMMENTS_UPDATE_FAIL';
export const SET_FOCUSED_CELL_EDITOR = 'pc/forcast/SET_FOCUSED_CELL_EDITOR';

const initialState = {
  newTab0: {
    isLoading: false,
    fcDropdownDataLoaded: false,
    fcDropdownDataLoading: false,
    fcDropdownData: [],
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    fcFormColumns: {},
    headerDetailsFc: [],
    fcFBData: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PC_FC_DROPDOWN_DATA_LOADING:
      return { ...state, fcDropdownDataLoading: true };
    case PC_FC_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        fcDropdownData: action.payload,
        fcDropdownDataLoading: false,
        requestDropdownDataLoaded: true,
      };
    case PC_FC_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        fcDropdownData: action.payload,
        fcDropdownDataLoading: true,
        fcDropdownDataLoaded: false,
      };
    case FC_COLUMNS:
    case COMMENTS_UPDATE:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case FC_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rowsInfo: action.payload,
        },
      };
    case FC_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          fcFormColumns: action.payload,
        },
      };
    case FC_COLUMNS_FAIL:
    case COMMENTS_UPDATE_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case VIEW_FC_DETAIL_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          headerDetailsFc: action.data,
        },
      };
    case FC_FB_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          fcFBData: action.payload,
        },
      };
    case FC_FB_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          error: action.payload,
        },
      };
    case COMMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: false },
      };
    case FC_DATA_SUBMIT:
      return { ...state, submittingItem: true };
    case FC_DATA_SUBMIT_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case FC_DATA_SUBMIT_FAIL:
      return { ...state, submittingItem: false };
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          focusedCell: {
            colId: action.colId,
            rowIndex: action.rowIndex,
          },
        },
      };

    default:
      return state;
  }
}

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const getFormValueByKey = (key, tabData, screenData) =>
  tabData[key] && !screenData && tabData[key].length > 0
    ? tabData[key].join()
    : tabData[key];

export function fetchFcDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: PC_FC_DROPDOWN_DATA_LOADING });
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'FC_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: PC_FC_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: PC_FC_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

function getfcFormColumns(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FC_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `FC`,
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;

        if (data && data.statusCode === '200') {
          if (
            data.result.columnDefs[13] &&
            data.result.columnDefs[13].field == 'projectendinginv'
          ) {
            data.result.columnDefs[13].cellStyle = function (params) {
              if (params.value >= 0) {
                return { backgroundColor: '#3eb05b' };
              } else {
                return { backgroundColor: '#FF0000' };
              }
            };
          }
          let configs = data.result;
          dispatch({
            type: FC_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: FC_COLUMNS_FAIL,
            payload: 'Error in loading fc form!',
            tabKey,
          });
        }
      });
  };
}

function getForwardBackwardData(tabKey, details) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'FB_DATA',
            view: `FC`,
            details: {
              email: Cookie.get('email'),
              customer_plant: details.customerPlant,
              supplier_name: details.supplierName,
              supplier_code: details.supplierCode,
              supplier_site: details.supplierSite,
              supplier_key: details.supplierDepot,
              agility_type: details.agilityType,
              part: details.partName,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          let configs = data.result;
          dispatch({
            type: FC_FB_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: FC_FB_FAIL,
            payload: 'Error in loading fc form!',
            tabKey,
          });
        }
        return response;
      });
  };
}

function getfcFormRows(tabKey, screenData = false) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const {
        pc: { search: s },
      } = store.getState();
      const {
        customerPlants,
        agilityTypesList,
        supplierCodeList,
        supplierDepotList,
        supplierNameList,
        supplierSiteList,
        loadingPlants,
        ...tabData
      } = s['quickSearch'][tabKey] || {};

      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `FC`,
              viewType: '',
              gridParams: { ...params.request, startRow: 0, endRow: 104 },
              details: {
                email: Cookie.get('email'),
                customer_plant: getFormValueByKey(
                  'customerPlant',
                  tabData,
                  screenData
                ),
                supplier_name: getFormValueByKey(
                  'supplierName',
                  tabData,
                  screenData
                ),
                supplier_code: getFormValueByKey(
                  'supplierCode',
                  tabData,
                  screenData
                ),
                supplier_key: getFormValueByKey(
                  'supplierDepot',
                  tabData,
                  screenData
                ),
                agility_type: getFormValueByKey(
                  'agilityType',
                  tabData,
                  screenData
                ),
                search_term: tabData.searchTerm,
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row) => {
              return {
                ...row,
                originalValues: { ...row },
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              data.result.lastRow < 104 ? data.result.lastRow : 104;
            store.dispatch({
              type: FC_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function updateComments(headerVal) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: COMMENTS_UPDATE });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            txn_type: 'COMMENT_UPDATE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
          },
          PartsArray: [headerVal],
        },
      })
      .then((response) => {
        const { data } = response;
        if (data) {
          const { TXN } = data;
          const overallStatus = TXN[0].return_msg;
          if (overallStatus === 'SUCCESS') {
            dispatch(update());
          } else {
            dispatch({
              type: COMMENTS_UPDATE_FAIL,
              payload: 'Error in updating user',
            });
          }
          return TXN[0].return_msg;
        }
      });
  };
  function update() {
    return { type: COMMENTS_UPDATE_SUCCESS };
  }
}

function prepareAndSubmitData(gridApi, headerDetails, t) {
  let caItemDetails = [];
  gridApi.forEachNode((node) => {
    caItemDetails.push({
      comment: node.data.comment,
      period: node.data.period,
      qty: node.data.commit_qty,
    });
  });

  return (dispatch, _getState, { api }) => {
    dispatch({ type: FC_DATA_SUBMIT });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            txn_type: '830RCommit',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          CommitPartArray: [
            {
              CommitDetailsArray: caItemDetails,
              customer_plant: headerDetails.cp,
              part: headerDetails.part_number,
              supplier_key: headerDetails.supplier_key,
            },
          ],
        },
      })
      .then((response) => {
        const { data } = response;
        if (data) {
          const { TXN } = data;
          if (TXN) {
            const overallStatus = TXN[0].return_msg;
            if (overallStatus === 'SUCCESS') {
              message.success(t('data_updated_successfully'));
            } else if (overallStatus === 'FAILURE') {
              const failureResponse = TXN[0].CommitPartArray;
              Modal.error({
                title: t('failed_transactions'),
                icon: <ExclamationCircleOutlined />,
                content: (
                  <div
                    style={{
                      maxHeight: 200,
                      minHeight: 100,
                      overflowY: 'auto',
                    }}>
                    <div style={{ borderBottom: '1px solid #CCC', padding: 5 }}>
                      Org Item(s)
                    </div>
                    {failureResponse.map((item) => (
                      <div
                        key={getUUID()}
                        style={{
                          borderBottom: '1px solid #CCC',
                          padding: 5,
                          fontSize: 12,
                        }}>
                        Plant: {item.customer_plant}
                        Part: {item.part}
                        Supplier Key: {item.supplier_key}
                      </div>
                    ))}
                  </div>
                ),
              });
            } else {
              message.error(t('something_went_wrong'));
            }
            //Refresh grid
            gridApi.stopEditing();
            gridApi.forEachNode((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
          } else {
            message.error(t('something_went_wrong'));
          }
        } else {
          message.error(t('something_went_wrong'));
        }
      });
  };
}

export function getHeaderDetailsFc(details, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'VIEW_PARTS_HEADER',
            view: `PARTS`,
            details: {
              email: Cookie.get('email'),
              partname: details.partName,
              supplierkey: details.supplierDepot,
              customercode: details.customerCode,
              customerplant: details.customerPlant,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(data, tabKey));
          }
          return data;
        }
        return response;
      });
  };
  function update(data, tabKey) {
    return { type: VIEW_FC_DETAIL_SUCCESS, data, tabKey };
  }
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
  screenData,
}) {
  const {
    pc: { search: s },
  } = store.getState();
  const {
    customerPlants,
    agilityTypesList,
    supplierCodeList,
    supplierDepotList,
    supplierNameList,
    supplierSiteList,
    loadingPlants,
    ...tabData
  } = s['quickSearch'][tabKey] || {};

  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                customer_plant: getFormValueByKey(
                  'customerPlant',
                  tabData,
                  screenData
                ),
                supplier_name: getFormValueByKey(
                  'supplierName',
                  tabData,
                  screenData
                ),
                supplier_code: getFormValueByKey(
                  'supplierCode',
                  tabData,
                  screenData
                ),
                supplier_key: getFormValueByKey(
                  'supplierDepot',
                  tabData,
                  screenData
                ),
                agility_type: getFormValueByKey(
                  'agilityType',
                  tabData,
                  screenData
                ),
                search_term: tabData.searchTerm,
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }
          return response;
        });
    }
  };
}

export {
  getfcFormColumns,
  getfcFormRows,
  prepareAndSubmitData,
  getForwardBackwardData,
  setFocusedCell,
};
